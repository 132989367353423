import axios from 'axios'
import {stringify} from 'qs'

const api = axios.create({
    baseURL: GG.actionUrl,
    headers: {
        'X-CSRF-Token': GG.csrfTokenValue
    },
    transformRequest: [
        function (data) {
            return stringify(data)
        }
    ]
})

let GivingStats = Garnish.Base.extend({
    $container: null,
    $statsTrigger: null,
    $triggerContentBtn: null,
    $closeStatsBtn: null,

    $loader: null,
    $statsContainer: null,

    type: '',

    init() {
        this.$container = $('#giving-stats')
        this.$statsTrigger = $('.giving-stats-trigger')
        this.$triggerContentBtn = $('.trigger-content')
        this.$statsContainer = $('#stats-container')
        this.$closeStatsBtn = $('.close-stats')
        this.$loader = this.$container.find('.loader')

        this.addListener(this.$statsTrigger, 'click', this.handleStatsTrigger)
        this.addListener(this.$triggerContentBtn, 'click', this.getContent)
        this.addListener(this.$closeStatsBtn, 'click', this.closeStats)
    },

    handleStatsTrigger(e) {
        e.preventDefault()

        if (this.$statsTrigger.hasClass('active')) {
            // Hide
            this.$statsTrigger.removeClass('active')
            this.$statsTrigger.attr('aria-expanded', 'false')

            this.$container.slideUp()
            this.$container.attr('aria-hidden', 'true')

            this.$closeStatsBtn.hide()
        } else {
            // Show
            this.$statsTrigger.addClass('active')
            this.$statsTrigger.attr('aria-expanded', 'true')

            this.$container.slideDown()
            this.$container.attr('aria-hidden', 'false')

            this.$container.eq(0).velocity('scroll', {
                duration: 300,
                offset: -120,
            });

            setTimeout($.proxy(function() {
                this.$closeStatsBtn.velocity('fadeIn')
            }, this), 500)

            // Trigger first tab
            $(this.$triggerContentBtn[0]).trigger('click')
        }
    },

    getContent(e) {
        e.preventDefault()
        let $target = $(e.currentTarget)
        const self = this
        this.type = $target.data('type')

        this.$loader.removeClass('off')

        this.$triggerContentBtn.removeClass('selected')
        this.$triggerContentBtn.attr('aria-current', 'false')

        $target.addClass('selected')
        $target.attr('aria-current', 'true')

        api.post('gg/stats/get-content', {
            type: this.type
        })
            .then(function (response) {
                self.loadHtml(response.data.template)

            })
            .catch(function (error) {
                // On error
                console.log('error', error)

            })
            .then(function () {
                // Always returns
            })
    },

    loadHtml(template) {
        this.$loader.addClass('off')
        this.$statsContainer.html(template)

        this.handleScripts()
    },

    closeStats() {
        this.$container.slideUp()
        this.$container.attr('aria-hidden', 'true')

        this.$statsTrigger.removeClass('active')
        this.$statsTrigger.attr('aria-expanded', 'false')


        this.$closeStatsBtn.hide()

        $('#meter').eq(0).velocity('scroll', {
            duration: 300,
            offset: -200,
        });
    },

    handleScripts() {
        $('.category-heading').on('click', function(e) {

            let target = $(this).data('target')

            if ($(this).hasClass('active')) {
                $(`#${target}`).stop().slideUp();
                $(this).removeClass('active')
            } else {
                $('.category-nonprofit-list').hide()
                $('.category-heading').removeClass('active')

                $(this).addClass('active')
                $(`#${target}`).stop().slideDown();
                $(`#${target}`).eq(0).velocity('scroll', {
                    duration: 300,
                    offset: -120,
                });
            }
        })
    }
})

Garnish.$doc.ready(function () {
    if ($('.giving-stats-trigger').length > 0) {
        GG.GivingStats = new GivingStats()
    }


})